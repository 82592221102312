exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-500-index-jsx": () => import("./../../../src/pages/500/index.jsx" /* webpackChunkName: "component---src-pages-500-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-registration-confirmed-index-jsx": () => import("./../../../src/pages/registration-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-registration-confirmed-index-jsx" */),
  "component---src-pages-registration-index-jsx": () => import("./../../../src/pages/registration/index.jsx" /* webpackChunkName: "component---src-pages-registration-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-resubscribe-confirmed-index-jsx": () => import("./../../../src/pages/resubscribe-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-resubscribe-confirmed-index-jsx" */),
  "component---src-pages-taking-lupkynis-index-jsx": () => import("./../../../src/pages/taking-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-taking-lupkynis-index-jsx" */),
  "component---src-pages-unsubscribe-confirmed-index-jsx": () => import("./../../../src/pages/unsubscribe-confirmed/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-confirmed-index-jsx" */),
  "component---src-pages-unsubscribe-index-jsx": () => import("./../../../src/pages/unsubscribe/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-jsx" */),
  "component---src-pages-what-is-lupkynis-index-jsx": () => import("./../../../src/pages/what-is-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-what-is-lupkynis-index-jsx" */),
  "component---src-pages-what-is-lupus-nephritis-index-jsx": () => import("./../../../src/pages/what-is-lupus-nephritis/index.jsx" /* webpackChunkName: "component---src-pages-what-is-lupus-nephritis-index-jsx" */),
  "component---src-pages-why-lupkynis-index-jsx": () => import("./../../../src/pages/why-lupkynis/index.jsx" /* webpackChunkName: "component---src-pages-why-lupkynis-index-jsx" */)
}

